import React from 'react'
import { Layout } from '../../components/AppLayout'

const GartnerReprintGenAI = () => {
  return (
    <Layout>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '80vh',
          textAlign: 'center',
          fontSize: '1.5rem',
          color: '#333',
        }}
      >
        This page is temporarily unavailable. Please check back later.
      </div>
    </Layout>
  )
}

export default GartnerReprintGenAI
